<template>
    <div>
      <v-layout wrap justify-center  :style="{
        background: 'linear-gradient(356deg, #E1EEFF87 100%, #E1EEFF87 8%, #FFFFFF 35%, #FFFFFF 0%)',
  
      }">
        <v-flex xs11 sm11 md10 >
          <v-layout wrap justify-start>
            <v-flex
              xs12
              sm12
              md12
              align-self-center
              text-left
              py-16
              data-aos="zoom-in-up"
              data-aos-ease="ease"
              data-aos-duration="1500"
              data-aos-delay="500"
              
            >
            <span 
  style="font-family: OutfitExtraBold; color:black; text-align:justify"
  :style="{
    'line-height': $vuetify.breakpoint.name == 'xs' ? '40px' : '30px',
    'font-size': $vuetify.breakpoint.name == 'xs' ? '30px' : '35px',
  }"
>
  C-DAC (Centre for Development of Advanced Computing)
</span>

              <br />
              <br />
              <v-flex text-justify>
              <span
              style="font-family: JostRegular; color: black; font-size: 18px; text-align: justify;"
                :style="{
                  'line-height':
                    $vuetify.breakpoint.name == 'xs' ? '15px' : '15px',
                  'font-size': $vuetify.breakpoint.name == 'xs' ? '15px' : '17px',
                }"
                >C-DAC is a national initiative, a mission to bring forth India as a major force in developing world class Information Technologies. C-DAC has developed various programmes for advancing computer education at the grass root level, imparting computer training to the mass carrying it to the remote regions of the country.<br><br>
                
                Under the PACE banner, training centres are called C-DAC PACE Authorized Training Centres. Apart from the medium of
                 teaching, this program gives the students an opportunity to make the most of IT without having a technical background. 
                 The courses are so structured that those desirous of pursuing a career in the software industry, can do so by
                  enrolling for the advanced courses offered by the ATCs (Advanced Computing Training School) of C-DAC.  
                  All courses from Zen Technologies are CDAC Certified.</span
              
              
                ></v-flex>
              <br />
              <!-- <v-flex xs12 pt-5>
                <v-btn
                  style=""
                  dark
                  large
                  rounded
                  class="text-capitalize"
                  :to="{
                    path: '/about',
                  }"
                  color="#3A81E6"
                >
                  <span style="font-family: JostBold; font-size: 16px"
                    >Learn More <v-icon>mdi-arrow-right</v-icon></span
                  >
                </v-btn>
              </v-flex> -->
            </v-flex>
            <!-- <v-flex xs12 sm6 md6 pt-15 align-self-end>
              <v-img src="./../../assets/images/homenewone.png"></v-img>
            </v-flex> -->
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center :style="{
        background: 'linear-gradient( 356deg, #E1EEFF87 20%, #E1EEFF87 1%, #FFFFFF 35%, #FFFFFF 0%)',
      }">
        <v-flex xs11 sm11 md10>
          <zencourse />
        </v-flex>
      </v-layout>
     
    </div>
  </template>
  <script>
  // import axios from "axios";
  import zencourse from "./zencourse";
  
  export default {
    components: {
        zencourse,
      
    },
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        category: [],
        safaries: [],
        accomodation: [],
        programlen: null,
        halfsize: null,
        model: 0,
      };
    },
    beforeMount() {},
    methods: {},
  };
  </script>