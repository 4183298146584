var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{style:({
        background: 'linear-gradient(356deg, #E1EEFF87 100%, #E1EEFF87 8%, #FFFFFF 35%, #FFFFFF 0%)',
  
      }),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm11":"","md10":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","align-self-center":"","text-left":"","py-16":"","data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_c('span',{staticStyle:{"font-family":"OutfitExtraBold","color":"black","text-align":"justify"},style:({
    'line-height': _vm.$vuetify.breakpoint.name == 'xs' ? '40px' : '30px',
    'font-size': _vm.$vuetify.breakpoint.name == 'xs' ? '30px' : '35px',
  })},[_vm._v(" C-DAC (Centre for Development of Advanced Computing) ")]),_c('br'),_c('br'),_c('v-flex',{attrs:{"text-justify":""}},[_c('span',{staticStyle:{"font-family":"JostRegular","color":"black","font-size":"18px","text-align":"justify"},style:({
                  'line-height':
                    _vm.$vuetify.breakpoint.name == 'xs' ? '15px' : '15px',
                  'font-size': _vm.$vuetify.breakpoint.name == 'xs' ? '15px' : '17px',
                })},[_vm._v("C-DAC is a national initiative, a mission to bring forth India as a major force in developing world class Information Technologies. C-DAC has developed various programmes for advancing computer education at the grass root level, imparting computer training to the mass carrying it to the remote regions of the country."),_c('br'),_c('br'),_vm._v(" Under the PACE banner, training centres are called C-DAC PACE Authorized Training Centres. Apart from the medium of teaching, this program gives the students an opportunity to make the most of IT without having a technical background. The courses are so structured that those desirous of pursuing a career in the software industry, can do so by enrolling for the advanced courses offered by the ATCs (Advanced Computing Training School) of C-DAC. All courses from Zen Technologies are CDAC Certified.")])]),_c('br')],1)],1)],1)],1),_c('v-layout',{style:({
        background: 'linear-gradient( 356deg, #E1EEFF87 20%, #E1EEFF87 1%, #FFFFFF 35%, #FFFFFF 0%)',
      }),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm11":"","md10":""}},[_c('zencourse')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }